.layout-config {
    backdrop-filter: blur(20px);
    background-color: var(--mobile-menu-bg);

    .p-selectbutton {
        .p-button {
            padding: 0.5rem 1rem;

            &:first-child {
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
            }

            &:last-child {
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
            }
        }
    }

    button {
        &:focus-visible {
            outline: 0 none;
            border-color: var(--surface-500) !important;
        }
    }

    .grid {
        > div {
            padding: 1rem;
        }
    }

    .p-inputswitch {
        .p-inputswitch-slider {
            border-radius: 30px;

            &:before {
                border-radius: 30px;
            }
        }
    }

    &.layout-light {
        background: var(--surface-overlay);
    }

    &.layout-dark {
        background: var(rgba(0,0,0,.3));
        border: 0 none;
    }
}
