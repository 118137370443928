.layout-topbar {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    min-height: $topBarHeight;
    background-color: var(--surface-card);
    transition: left $transitionDuration;
    display: block;
    align-items: center;
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);
}
